.menu-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: absolute;
	background: rgba(103, 104, 101, 0.66);
	backdrop-filter: blur(5px);
	color: #ffffff;
	width: 100%;
	padding: 0.05rem;
	height: 1.6rem;
	box-sizing: border-box;
	font-size: 0.875rem;
	font-weight: 500;
	top: -1px;
}

.menu-bar img {
	height: 70%;
	padding: 0.1rem;
  }
  
  .menu-bar .app-menus > div:nth-child(2) {
	font-weight: 700;
  }
  
  .menu-bar .app-menus > div .apple {
	height: 70%;
	padding: 0.1rem;
  }
  
  .menu-bar .app-menus > div {
	margin-left: 1.45rem;
  }
  
  .menu-bar .right-side {
	float: right;
  }
  
  .menu-bar .right-side > div {
	margin-right: 1rem;
  }
  
  .menu-bar .right-side > div img {
	height: 50%;
	padding: 0.25rem;
	opacity: 0.8;
  }
  
  .menu-bar .right-side > div:nth-child(4) img {
	height: 70%;
	padding: 0.2rem;
  }
  
  .menu-bar .right-side > div:last-child {
	margin-right: 1.45rem;
	font-size: 0.875rem;
  }
  
  .menu-bar .right-side > div .time {
	margin-left: 0.4em;
  }
  
  .menu-bar .app-menus,
  .menu-bar .right-side {
	height: 100%;
	display: flex;
	align-items: center;
  }
  
  .menu-bar .app-menus > div,
  .menu-bar .right-side > div {
	height: min-content;
	display: inline-block;
	vertical-align: top;
  }
  
  .menu-bar .img-container {
	height: 100% !important;
	display: flex !important;
	align-items: center;
  }
  